import $ from 'jquery'
$(document).ready( initMainSlider )
$(document).ready( initMobileSlider )

function initMainSlider(){
  if ($('.js-slider').length) {
    console.log('initMainSlider')
    $('.js-slider').slick({
      arrows: true,
      dots: true,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
    });
  }
}

function initMobileSlider() {
  let slider = $('.js-mobile-slider');
  let settings = {
    mobileFirst: true,
    dots: true,
    arrows: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 959,
        settings: "unslick"
      }
    ]
  };
  slider.slick(settings);
  $(window).on("resize", function () {
    if ($(window).width() > 959) {
      return;
    }
    if (!slider.hasClass("slick-initialized")) {
      return slider.slick(settings);
    }
  });
}