import $ from 'jquery'

const $doc = $(document)

function toggleAccordionItem() {
  let el = $(this);
  if ( el.parent().parent().hasClass('active') ) {
    el.attr('aria-expanded', 'false');
  } else {
    el.attr('aria-expanded', 'true');
  }
  if(el.hasClass('accordion__single-item-icon')) {
    el.parent().parent().parent().toggleClass('active');
  } else {
    el.parent().parent().toggleClass('active');
  }

  $('html,body').animate({
    scrollTop: el.offset().top - 200
  }, 500); 
   
}

$doc.on('click', '.js-accordion-item button', toggleAccordionItem )
