import $ from 'jquery'
$(document).ready(initFeaturedPosts)

function initFeaturedPosts(){
  $('.js-open-button').on('click', function (event) {
    let isMobile = $(window).width() <= 1090;
    if (isMobile) {
      let el = $(this);
      el.parent().toggleClass('is-open');
      if (el.parent().hasClass('is-open')) {
        el.next().attr('aria-expanded', 'true')
        el.hide()
        el.next().find('.js-full-button > .btn-text').text('VIEW FULL ARTICLE')
      } else {
        el.next().attr('aria-expanded', 'false')
      }
    }
  });
}
