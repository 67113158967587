import $ from 'jquery'
const  $doc = $(document)

$doc.ready( ()=>{
  const hash = window.location.hash
  if (hash) {
    $('html,body').animate({
      scrollTop: $(hash).offset().top - 200
    });
  }
  $('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .not('.js-open-modal')
    .click(function (event) {
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
        &&
        location.hostname == this.hostname
      ) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top -200
          }, 400, function () {
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) {
              return false;
            } else {
              $target.attr('tabindex', '-1'); 
              $target.focus(); 
            };
          });
        }
      }
    });
})
