import $ from 'jquery'
$(document).ready( initInsightsSlider )

function initInsightsSlider(){
  if ($('.js-insights-slider').length) {
    $('.js-insights-slider').slick({
      arrows: false,
      dots: true,
      slidesToShow: 3.5,
      slidesToScroll: 3,
      infinite: false,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });
  }
}
