import $ from 'jquery'

$(document).ready(function(){
    const $sliders = $('.js-ticker:not(.slick-initialized)')
    if ( $sliders.length ) {
        $sliders.each(function () {
            let $el = $(this);
            $el.slick({
                arrows: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 0,
                cssEase: 'linear',
                variableWidth: true,
                draggable: false,
                pauseOnFocus: true,
                pauseOnHover: true,
                focusOnSelect: true
            }).on("beforeChange", function(e, slick) {
                var width = $('.js-ticker .slick-current').outerWidth();
                var speed = (width*3600)/100;
                $('.js-ticker').slick("setOption", "speed", speed);
            });
        });
    }
    $('.js-ticker.slick-initialized a').click(function() {
        console.log('its clicked')
        let $active = $(this).closest('.js-ticker');
        console.log($active)
        $active.slick('slickPause');
    })
})